<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>    

    <SingleBlog :blog="blog" />
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import SingleBlog from '../../organisms/SingleBlog/SingleBlog.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, SocialBox, SingleBlog, Carousel
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        blog: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            
        }
    },
}
</script>

<style>

</style>