<template>
  <BlogDetailsTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :mediaInfo="mediaInfo"
    :blog="blog"
  />
</template>

<script>
import BlogDetailsTemplate from "@/components/templates/BlogDetails/BlogDetails";
import {
  headerProps,
  socialProps,
  singleBlog,
} from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
export default {
  components: {
    BlogDetailsTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      blog: singleBlog,
    };
  },
};
</script>

<style scoped></style>
